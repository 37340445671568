exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-cart-tsx": () => import("./../../../src/pages/cart.tsx" /* webpackChunkName: "component---src-pages-cart-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-privacy-policy-tsx": () => import("./../../../src/pages/privacy-policy.tsx" /* webpackChunkName: "component---src-pages-privacy-policy-tsx" */),
  "component---src-pages-terms-and-conditions-tsx": () => import("./../../../src/pages/terms-and-conditions.tsx" /* webpackChunkName: "component---src-pages-terms-and-conditions-tsx" */),
  "component---src-templates-category-eyes-tsx": () => import("./../../../src/templates/category-eyes.tsx" /* webpackChunkName: "component---src-templates-category-eyes-tsx" */),
  "component---src-templates-category-face-tsx": () => import("./../../../src/templates/category-face.tsx" /* webpackChunkName: "component---src-templates-category-face-tsx" */),
  "component---src-templates-category-lips-tsx": () => import("./../../../src/templates/category-lips.tsx" /* webpackChunkName: "component---src-templates-category-lips-tsx" */),
  "component---src-templates-category-neck-tsx": () => import("./../../../src/templates/category-neck.tsx" /* webpackChunkName: "component---src-templates-category-neck-tsx" */),
  "component---src-templates-product-tsx": () => import("./../../../src/templates/product.tsx" /* webpackChunkName: "component---src-templates-product-tsx" */)
}

