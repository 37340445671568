import * as React from "react";

type TCartProduct = {
  id: string;
  name: string;
  price: string;
  quantity: number;
  image: string;
  unit_amount: number;
};

type TDelivery = {
  boxId: string;
  address: string;
};

type TCustomer = {
  fullName: string;
  phone: string;
  email: string;
};

export type TCartAddProduct = Partial<Queries.StripeProduct> & {
  price: string;
  unit_amount: number;
};

type TCartContext = {
  items: TCartProduct[];
  addItem: (item: TCartAddProduct) => void;
  removeItem: (id: string) => void;
  checkout: () => void;
  delivery: TDelivery | null;
  updateDelivery: (nextDelivery: TDelivery) => void;
  customer: TCustomer;
  updateCustomer: (
    s: keyof TCustomer
  ) => (e: React.ChangeEvent<HTMLInputElement>) => void;
};

const defaultCustomer = {
  fullName: "",
  phone: "",
  email: "",
};

const CartContext = React.createContext<TCartContext>({
  items: [],
  addItem: (id) => {},
  removeItem: (id) => {},
  checkout: () => {},
  delivery: null,
  updateDelivery: (nextDelivery) => {},
  customer: defaultCustomer,
  updateCustomer: (s: string) => (e) => {},
});

export const CartProvider = ({ children }: { children: React.ReactNode }) => {
  const [cart, setCart] = React.useState<TCartProduct[]>([]);
  const [delivery, setDelivery] = React.useState<TDelivery | null>(null);
  const [customer, setCustomer] = React.useState<TCustomer>(defaultCustomer);
  const addItem = React.useCallback(
    (item: TCartAddProduct) => {
      const found = cart.find(({ price }) => price === item.default_price!);

      if (found) {
        found.quantity += 1;
        setCart([...cart]);
        return;
      }

      setCart([
        ...cart,
        {
          id: item.id!,
          name: item.name!,
          price: item.default_price!,
          quantity: 1,
          image: item.images![0]!,
          unit_amount: item.unit_amount!,
        },
      ]);
    },
    [setCart, cart]
  );
  const removeItem = React.useCallback(
    (id: string) => setCart((cart) => cart.filter((item) => item.id !== id)),
    [setCart]
  );
  const checkout = React.useCallback(() => {
    if (!delivery) return;

    fetch("/api/checkout", {
      headers: {
        "Content-type": "application/json",
      },
      method: "POST",
      body: JSON.stringify({ products: cart, delivery, customer }),
    })
      .then((res) => res.json())
      .then((json) => {
        window.location.href = json.url;
      })
      .catch((e) => console.log(e));
  }, [cart, delivery, customer]);
  const updateDelivery = React.useCallback(
    (nextDelivery: TDelivery) => setDelivery(nextDelivery),
    [setDelivery]
  );
  const updateCustomer =
    (field: keyof TCustomer) => (e: React.ChangeEvent<HTMLInputElement>) => {
      setCustomer((customer) => ({
        ...customer,
        [field]: e.target.value,
      }));
    };

  return (
    <CartContext.Provider
      value={{
        items: cart,
        addItem,
        removeItem,
        checkout,
        delivery,
        updateDelivery,
        customer,
        updateCustomer,
      }}
    >
      {children}
    </CartContext.Provider>
  );
};

export const useCart = () => {
  const cart = React.useContext(CartContext);

  if (!cart) throw new Error("No cart context");

  return cart;
};
